

.main-container {
    /* height: 100vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* .main-container h2{
    margin: 0 0 80px 0;
    color: blueviolet;
    font-size: 30px;
    font-family: "Raleway", sans-serif;
    font-weight: 400;
} */

/* .radio-buttons {
    width: 100%;
    margin: 0 auto;
    text-align: center;
} */
.custom-radio input{
    display: none;
}
.radio-btn {
    margin: 10px;
    width: 180px;
    height: 200px;
    /* border: 1px solid transparent; */
    border: 0.5px solid #286ab0;;
    
    
    display: inline-block; 
    border-radius:  10px;
    position: relative;
    text-align: center;
    box-shadow: 0 0 20px #f3eeee67;
    cursor: pointer;
}

.radio-btn > i{
    color: #fff;
    background-color: #8373e6;
    font-size: 20px;
    position: absolute;
    top: -15px;
    left: 50%;
    transform:translateX(-50%) scale(4);
    border-radius: 50px;
    padding: 3px;
    transition: 0.2s;
    pointer-events: none;
    opacity: 0;
}

/* .radio-btn .hobbies-icon {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);

} 
*/
/* .radio-btn .hobbies-icon i {
    color: #8373e6;
    line-height: 80px;
    font-size:  60px;
} */

/* .radio-btn .hobbies-icon h3 {
    color: #8373e6;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-weight: 400;
  
} */

.custom-radio input[type='radio']:checked + .radio-btn  {
    border: 3px solid #8373e6;
}

.custom-radio input:checked +.radio-btn > i {
    opacity: 1;
    transform: translateX(-50%) scale(1)
}


/* -------------------------- */
 .checkbox-container {
    align-items: center;
    justify-content: center;
    flex-direction: column;
     
 }

 .custom-checkbox input {
     display: none 
 }
 .checkbox-btn{
    margin: 10px;
    width: 180px;
    height: 200px;
    border: 1px solid transparent;
    border: 0.5px solid #286ab0;;
     
    
    display: inline-block; 
    border-radius:  10px;
    position: relative;
    text-align: center;
    box-shadow: 0 0 20px #f3eeee67;
    cursor: pointer;
   
 }

 .checkbox-btn > i{
    color: #fff;
    background-color: #8373e6;
    font-size: 20px;
    position: absolute;
    top: -15px;
    left: 50%;
    transform:translateX(-50%) scale(2);
    border-radius: 50px;
    padding: 3px;
    transition: 0.2s;
    pointer-events: none;
     opacity: 0;
}


 .custom-checkbox input[type='checkbox']:checked + .checkbox-btn {
    border: 3px solid #8373e6;
}


 .custom-checkbox input:checked + .checkbox-btn > i {
    border: 3px solid #8373e6;
    opacity: 1;
    transform: translateX(-50%) scale(1)

 }

